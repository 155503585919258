<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import useProducts from "../../../hooks/useProducts";
import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router/auto";
import ProductLayout from "../../../layouts/ProductLayout.vue";
import { setPageTitle } from "../../../utils/pageTitle";
import { useThemeStore } from "@/stores/themeStore";
import {
  connectToServerApi
} from "@/utils/agGridUtils";
import Warehouses from '@/components/common/Warehouses.vue';

const PRODUCT_EXTERNAL_FILTERS_KEY = "products-external-filter";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const {
  fetchProduct,
  product,
} = useProducts();

const route = useRoute();
const themeStore = useThemeStore();
let gridApi;
let context = ref(null);

const PALLETS_URL = `/products/${route.params.id}/lps`;

onBeforeMount(async () => {
  await fetchProduct(route.params.id);
  setPageTitle(`Product -${product.value.id}`);
});

const onGridReady = async (params) => {
  gridApi = params.api;
  context.value = {
    apiUrl: PALLETS_URL,
    filterKey: PRODUCT_EXTERNAL_FILTERS_KEY,
    advancedFilterKeys: []
  }
  gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const columnDefs = [
  { headerName: "PLT #", cellDataType: "text", valueGetter: "node.rowIndex + 1" },
  { field: "number", 
    headerName: "LP Number", 
    cellDataType: "text",    
    cellRenderer: function (params) {
      if (params.data.number) {
        return (
          '<a href="">' +
          params.data.number +
          "</a>"
        );
      } else {
        return '<a href=""';
      }
    }, 
},
  {
    field: "lot",
    headerName: "Location",
    cellDataType: "text",
    cellRenderer: function (params) {
      if (params.data.lot) {
        return (
          '<a href="">' +
          params.data.lot +
          "</a>"
        );
      } else {
        return '<a href=""';
      }
    },
  },
  {
    field: "product.sku",
    headerName: "SKU",
    cellDataType: "text",
    cellRenderer: function (params) {
      if (params.data.product.sku) {
        return (
          '<a href="/products/' +
          params.data.id +
          '" target="_blank">' +
          params.data.product.sku +
          "</a>"
        );
      } else {
        return '<a href=""';
      }
    },
  },
  { field: "expiry", headerName: "Expiration (yyyy-mm-dd)", cellDataType: "text" },
  { field: "lot", headerName: "Lot Code", cellDataType: "text" },
  { field: "pallet_qty", headerName: "Qty", cellDataType: "text" },
];

const defaultColDef = {
  sortable: true,
  suppressHeaderMenuButton: true,
  width: 120,
};

const gridOptions = {
  rowModelType: "serverSide",
  autoSizeStrategy: {
    type: "fitGridWidth",
    defaultMinWidth: 100,
  },
};

const warehouseFiltersChangedCallback = () => {
  gridApi.onFilterChanged();
}
</script>

<template>
  <div id="grid-wrapper">
    <Warehouses class="mb-6" :callBack="warehouseFiltersChangedCallback" :parent-filter="PRODUCT_EXTERNAL_FILTERS_KEY" />
    <ProductLayout :product="product">
      <div class="ml-4 mb-4">
        <h4 class="text-center">Pallets</h4>
      </div>
      <div class="pallet-table d-flex">
        <AgGridVue
          style="width: 100%; height: 100%"
          :class="themeStore.agTheme"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :context="context"
        >
        </AgGridVue>
      </div>
    </ProductLayout>
  </div>
</template>

<style scoped>
.pallet-table {
  height: 600px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
